.cursor {

}

.cursor .name {
    vertical-align: bottom;
    font-family: monospace;
}

.identity-panel {
    font-family: monospace;
    display: flex;
    flex-direction: row;
}

.identity-panel .greeting {
    align-self: center
}

.identity-panel .greeting .name {
    text-decoration: dashed underline;
    font-size: 1.2em;
}

.identity-panel .cursor {
    align-self: center
}

.identity-panel input {
    font-size: 1.2em;
}