.reactive-toggle {
    filter: grayscale(100%);
    cursor: pointer;
    margin: 0.2rem;
}

.reactive-toggle:hover {
    filter: none;
}

.reactive-toggle.active {
    filter: none;
}