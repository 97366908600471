.text-editor-modal-container {
    height: 100%;
}

.text-editor-modal-container > .editor {
    height: 90%;
}

.text-editor-modal-container > .actions {
    display: flex;
    margin: 0.5rem;
    justify-content: right;
}

.save-button {
    padding: 0.5rem;
    font-size: 1.5rem;
    background-color: cornflowerblue;
    border: 1px solid cornflowerblue;
    border-radius: 0.5rem;
}

.cancel-button {
    font-size: 1.5rem;
    background-color: transparent;
    border: 1px solid cornflowerblue;
    border-radius: 0.5rem;
}