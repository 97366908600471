.picture-node-container {
    border-radius: 5px;
    border: 5px brown solid;
    overflow-y: scroll;
    height: inherit;
    width: inherit;
}

.picture-node-container img {
    display: block;
}

.picture-node-container .handle {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: white;
    transform: rotate(90deg);
    cursor: nwse-resize;
}
