.script-editor-modal-container {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    overflow-x: scroll;
}


.script-editor-modal-container > .header {
    display: flex;
    margin-bottom: 1rem;
    flex-flow: row nowrap;
}

.script-editor-modal-container > .header input {
    font-size: 1.2em;
    border: none;
}

.script-editor-modal-container > .workbench {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    height: 100%;
    max-width: 100%;
}

.script-editor-modal-container > .workbench > .editor {
    height: 100%;
    flex: 1 1 0;
    min-width: 0;
}

.script-editor-modal-container > .workbench > .output-area {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 0;
    overflow: scroll;
}

.script-editor-modal-container > .workbench > .output-area > .data {
    height: 100%;
    font-family: monospace;
    font-size: 1rem;
}

.script-editor-modal-container > .workbench > .output-area > .data > .error {
    color: red;
}

.script-editor-modal-container > .workbench > .output-area > .filters {
    display: flex;
    flex-flow: row nowrap;
}

.script-editor-modal-container > .actions {
    display: flex;
    margin: 0.5rem;
    justify-content: right;
}

.save-button {
    padding: 0.5rem;
    font-size: 1.5rem;
    background-color: cornflowerblue;
    border: 1px solid cornflowerblue;
    border-radius: 0.5rem;
}

.cancel-button {
    font-size: 1.5rem;
    background-color: transparent;
    border: 1px solid cornflowerblue;
    border-radius: 0.5rem;
}

.output-button {
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid cornflowerblue;
    border-radius: 0.5rem;
    align-self: flex-end;
}

.output-button:hover {
    background-color: cornflowerblue;
}

.output-button.active {
    background-color: cornflowerblue;
}
