.context-menu {
    position: absolute;
    z-index: 1000;
}

.context-menu > .item {
    display: block;
    padding: 5px 10px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.context-menu > .item:hover {
    background: #f6f6f6;
}