
.react-flow__handle.new-handle {
    visibility: hidden;
}

.react-flow__handle.new-handle:hover, div:not(.react-flow__handle):hover ~ .react-flow__handle.new-handle {
    visibility: visible;
}

/* first child of a node*/
.react-flow__node > div:first-child::before  {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: 150%;
    height: 150%;
    top: -25%;
    left: -25%;
    z-index: -1;
}

.new-handle.easy {
    transform: unset;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    border-radius: 0;
    background: none;
    z-index: -1;
}

.new-handle.easy.target {
    z-index: 100;
}