/*
 structure:
 .uploads-status
    .upload
        .error.icon or .success.icon or .loading.icon
        .filename
        .dismiss.button
        .progress
        .error
 */

.uploads-status {
    border: 5px solid #bbffdb;
    border-radius: 5px;
    padding: 10px;
    min-width: 300px;
}

.upload {
    padding-top: 5px;
    padding-bottom: 5px;
}

.upload:nth-child(even) {
    background: #f6f6f6;
}

.icon {
    background-size: contain !important;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    margin-right: 5px;
}

.error.icon {
    background: url(./icons/error.png) no-repeat;
}

.success.icon {
    background: url(./icons/success.png) no-repeat;
}

.loading.icon {
    background: url(./icons/loading.gif) no-repeat;
}

/* filename should be on the same line as the icon */
.filename {
    display: inline-block;
    vertical-align: top;
    font-family: monospace;
    font-size: 120%;
}

.dismiss.button {
    float: right;
    background: url(./icons/dismiss.png) no-repeat;
    background-size: contain !important;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
}
