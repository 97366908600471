.markdown-node-container {
    border: 1px solid purple;
    border-radius: 3px;
    overflow-y: scroll;
    height: inherit;
    width: inherit;
    min-width: 50px;
    min-height: 30px;
}


.selected .markdown-node-container {
    border: 3px solid deeppink;
}

.markdown-node-container .collapse-button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.markdown-node-container .expand-button {
    position: absolute;
    top: 0;
    right: 0;
}