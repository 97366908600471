.text-node-container {
    border: 1px solid purple;
    border-radius: 3px;
    overflow-y: scroll;
    height: inherit;
    width: inherit;
}


.selected .text-node-container {
    border: 3px solid deeppink;
}