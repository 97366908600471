.react-flow__node-default {
    padding: 0;
    border: 0;
    background: unset;
    text-align: unset;
}

.generic-node {
    border-radius: 5px;
    border: 1px solid #46c97c;
    font-family: monospace;
    width: inherit;
    height: inherit;
    padding: 5px;
}

.generic-node .title {
    color: darkslategrey;
    font-weight: bold;
}

.generic-node .content {
    color: grey;
    overflow: hidden;
}